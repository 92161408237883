import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import LogoPr from '../../../assets/LogoPr.jpg';
import LogoPRO from '../../../assets/LogoPRO.png';
import './../../../stylesComponents/sCClient/stylesTemplates/Template1.css';

function Template1() {
    const user = JSON.parse(localStorage.getItem('user'));
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');

    useEffect(() => {
        const email = Cookies.get('email');
        //console.log("Email récupéré du cookie:", email);

        if (email) {
            const [fullName] = email.split('@');
            const nameParts = fullName ? fullName.split('.') : [];

            let firstName = '';
            let lastName = '';

            if (nameParts.length === 2) {
                firstName = nameParts[0];
                lastName = nameParts[1];
            } else if (nameParts.length === 1) {
                firstName = nameParts[0];
                lastName = '';
            } else {
                console.warn("Le format de l'email ne correspond pas au format attendu prénom.nom@domaine.com");
            }

            const capitalize = (str) => str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

            console.log('Prénom extrait:', firstName);
            console.log('Nom extrait:', lastName);

            const prenom = capitalize(firstName);
            const nom = capitalize(lastName);

            setFirstname(prenom);
            setLastname(nom);
        } else {
            console.warn('Email non trouvé dans les cookies');
        }
    }, []);

    return (
        <div className="tp1-container">
            <div className="logo-container">
                <img src={LogoPr} alt="logo" className="logo-top" />
            </div>

            <div className="tp1-body-container">
                <div className="coord-container">
                    <div className="header-contact">
                        <div className="title-contact">
                            <h3 style={{ fontSize: '18px', lineHeight: '20px' }}>Nous contacter :</h3>
                        </div>
                    </div>
                    <div className="coord-data">
                        <div className="company-container">
                            <div className="company-data">
                                <p>prodigeoassurances.com</p>
                                <p style={{ marginTop: '10px' }}>PRODIGÉO ASSURANCES</p>
                                <p>93901 BOBIGNY CEDEX 9</p>
                                <p>Accueil au 04 93 22 73 76</p>
                            </div>
                        </div>
                        <div className="client-container">
                            <div className="client-data">
                                <p>
                                    {user.nom} {user.prenom}
                                </p>
                                <p>
                                    {user.adress}
                                    <br></br>
                                    {user.cp} {user.ville}
                                </p>
                            </div>
                            <div className="date-effect">
                                <p>
                                    {user.ville}, le {user.datecreation}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contract-details-container">
                    <div className="contract-details">
                        <p>Vos réferences : {user.muna}</p>
                        <br />
                        <p>Votre étude : Contrat collectif facultatif</p>
                    </div>
                </div>

                <div className="tp1-body">
                    <div className="tp1-body-info">
                        <p>{user.civilite}, Suite a notre entretien, vous trouverez ci-après :</p>
                        <ul>
                            <li>la (ou les) proposition(s) tarifaire(s)</li>
                            <li>le bulletin individuel d’adhésion à compléter et signer</li>
                            <li>le tableau récapitulatif des garanties</li>
                        </ul>
                        <p>Pour mettre en place vos garanties, c'est simple :</p>{' '}
                        <p>
                            Il vous suffit de nous retourner votre bulletin individuel d'adhésion signé, accompagné des
                            pièces justificatives demandées.
                        </p>
                        <ul>
                            <li>
                                Depuis votre Espace abonné «Mon compte» pour un suivi plus rapide sur{' '}
                                <i className="lien" style={{ cursor: 'pointer' }}>
                                    https://www.prodigeoassurances.com
                                </i>
                            </li>
                            <li>ou par courrier (voir adresse postale en tête de page).</li>
                        </ul>
                        <p style={{ lineHeight: '30px' }}>Vous avez besoin d'un conseil, d'une information ?</p>
                        <p>
                            Notre équipe de conseillers est à votre écoute au 01 57 63 66 13 du lundi au vendredi de
                            8h30 à 19h et le samedi de 8h30 à 13h.
                        </p>
                        <div className="signature">
                            <p>Je vous prie d'agréer, {user.civilite}, l'expression de mes sentiments distingués. </p>
                            <p>
                                Votre conseiller PRODIGÉO Assurances
                                <br />
                                <span style={{ lineHeight: '25px' }}>
                                    <i>{firstName}</i> <i>{lastName}</i>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tp1-footer">
                <img className="tp1-logo-footer" src={LogoPRO} alt="alt" />
            </div>
        </div>
    );
}

export default Template1;
