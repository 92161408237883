import React, { useEffect, useState } from 'react';
import Select from 'react-select';

function ConditionHab({
    selectedFormule,
    selectedOption,
    selectedBranch,
    champs,
    optionsFiltrees,
    handleChampChanger,
    handleRemoveChamp,
    handleNumEnfantsChange,
    handleNumAscendantsChange,
    handleAddChamp,
    numsEnfants,
    numsAscendants,
    selectedChamps
}) {

    return (
        <div>
            {selectedFormule?.nomFormule === 'Isolé' &&
                selectedOption?.nomOption === 'Option 2' &&
                selectedBranch.includes('Habitat') && (
                    <div className="formUpdate-body">
                        {champs.map((_, index) => (
                            <div className="formUpdate-center" key={index}>
                                <div className="formUpdate-center-select-container">
                                    <div className="formUpdate-center-select">
                                        <label className="select-label champ-label">Personne couverte :</label>
                                        <Select
                                            className="select-champ"
                                            options={optionsFiltrees} // Utiliser les options filtrées
                                            onChange={(selectedChamp) => handleChampChanger(selectedChamp, index)}
                                            placeholder="Choisissez un champs..."
                                            value={
                                                selectedChamps[index] &&
                                                selectedChamps[index].label &&
                                                selectedChamps[index].label.toLowerCase() !== 'salarié'
                                                    ? selectedChamps[index]
                                                    : null
                                            }
                                        />
                                    </div>
                                    <button
                                        className="formUpdate-btn-ayant-sup"
                                        onClick={() => handleRemoveChamp(index)}
                                    >
                                        Supprimer
                                    </button>
                                </div>
                                {selectedChamps[index] &&
                                    selectedChamps[index].label &&
                                    selectedChamps[index].label.includes('Enfant(s)') && (
                                        <div className="formUpdate-select-sup">
                                            <label className="select-label">Nombre d'enfants :</label>
                                            <Select
                                                value={numsEnfants[index]}
                                                onChange={(selectedOption) =>
                                                    handleNumEnfantsChange(index, selectedOption)
                                                }
                                                options={
                                                    selectedBranch.includes('Habitat')
                                                        ? [
                                                              { value: 1, label: '1' },
                                                              { value: 2, label: '2' },
                                                              { value: 3, label: '3 ou +' }
                                                          ]
                                                        : [
                                                              { value: 1, label: '1' },
                                                              { value: 2, label: '2 ou +' }
                                                          ]
                                                }
                                                placeholder="Choisissez le nombre d'enfants..."
                                                className="select-input"
                                            />
                                        </div>
                                    )}
                                {selectedChamps[index] &&
                                    selectedChamps[index].label &&
                                    selectedChamps[index].label.includes('Ascendant(s)') && (
                                        <div className="formUpdate-select-sup">
                                            <label className="select-label">Nombre d'ascendants :</label>
                                            <Select
                                                value={numsAscendants[index]}
                                                onChange={(selectedOption) =>
                                                    handleNumAscendantsChange(index, selectedOption)
                                                }
                                                options={[
                                                    { value: 1, label: '1' },
                                                    { value: 2, label: '2' },
                                                    { value: 3, label: '3' },
                                                    { value: 4, label: '4' }
                                                ]}
                                                placeholder="Choisissez le nombre d'ascendants..."
                                                className="select-input"
                                            />
                                        </div>
                                    )}
                            </div>
                        ))}
                        <div className="container-option2">
                            <button className="formUpdate-option2-btn" onClick={handleAddChamp}>
                                Ajouter un ayant-droit
                            </button>
                        </div>
                    </div>
                )}
        </div>
    );
}

export default ConditionHab;
