import React, { useState, useEffect } from 'react';
import './../../../stylesComponents/sCClient/stylesTemplates/Template2.css';
import Cookies from 'js-cookie';
import { useConfig } from '../../../Context/ConfigContext';

function Template2() {
    const { pmss, year } = useConfig();

    const [resultat, setResultat] = useState('');
    const [percentage, setPercentage] = useState('');
    const [nomBranches, setNomBranches] = useState('');

    useEffect(() => {
        const resultatData = Cookies.get('resultat');
        const percentageData = Cookies.get('percentage');
        const nomBranche = Cookies.get('branche');
        setPercentage(percentageData);
        setResultat(resultatData);
        setNomBranches(nomBranche);
    }, []);

    const user = JSON.parse(localStorage.getItem('user'));
    const resultats = Cookies.get('resultat') || {};
    const percentages = Cookies.get('percentage') || {};
    const numConjoints = Cookies.get('numConjoints');

    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };

    const userData = Cookies.get('user');
    const parsedUser = userData ? JSON.parse(userData) : {};
    const conjoint = numConjoints === '1' ? 'oui' : 'non';

    const getFormuleValue = () => {
        const selectedChampsCookie = Cookies.get('selectedChamps');
        const selectedChamps = selectedChampsCookie ? JSON.parse(selectedChampsCookie) : [];

        const isOnlySalarie =
            selectedChamps.length === 1 &&
            selectedChamps.some(
                (champ) =>
                    champ?.label?.toLowerCase().includes('salarié') || champ?.nom?.toLowerCase().includes('salarié')
            );

        const hasConjoint = selectedChamps.some((champ) => champ.label && champ.label.includes('Conjoint'));

        const branchCookie = Cookies.get('branche');
        const updateFormule = Cookies.get('updateFormule');
        const formule = Cookies.get('setFormule');

        console.log('updateFormule', updateFormule);

        if (branchCookie && branchCookie.includes('Architecte')) {
            if (updateFormule) {
                return updateFormule.length === 0 ? formule : updateFormule;
            } else if (isOnlySalarie) {
                return Cookies.get('setFormule');
            } else {
                return formule;
            }
        } else {
            return formule;
        }
    };

    const [data, setData] = useState({
        muna: parsedUser.muna || false,
        datecreation: parsedUser.datecreation,
        branche: Cookies.get('branche') || '',
        regime: Cookies.get('setRegime') || '',
        optiobli: Cookies.get('setOption') || '',
        formule: getFormuleValue() || '',
        nbenfants: '',
        nbascandants: '',
        conjoint: '',
        newRegime: Cookies.get('setRegime') || '',
        newOptiobli: Cookies.get('setOptionUpdate') || '',
        newFormule: getFormuleValue() || '',
        newNbenfants: '',
        newNbascandants: '',
        newConjoint: '-',
        resultat: resultats || '0',
        percentage: percentages || '0',
        userDate: '',
        nom: parsedUser.nom,
        prenom: parsedUser.prenom,
        adress: parsedUser.adress,
        cp: parsedUser.cp,
        ville: parsedUser.ville,
        civilite: parsedUser.civilite,
        comprenom: '',
        comnom: '',
        pmss: pmss
    });

    const formatDateFR = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR');
    };

    useEffect(() => {
        const email = Cookies.get('email');
        const numEnfant = Cookies.get('numEnfants');
        const numAscendants = Cookies.get('numAscendants');
        const numEnfantsUpdate = Cookies.get('updatenumEnfants') || numEnfant;
        const numAscendingUpdate = Cookies.get('updatenumAscendants') || numAscendants;
        const isConjointBox = Cookies.get('isConjointbox');
        const conjointStatus = isConjointBox === 'true' ? 'oui' : 'non';
        const numConjointsUpdate = Cookies.get('numConjointsUpdate');
        const branched = Cookies.get('branche');

        if (email) {
            const [fullName] = email.split('@');
            const nameParts = fullName ? fullName.split('.') : [];

            let firstName = '';
            let lastName = '';

            if (nameParts.length === 2) {
                firstName = nameParts[0];
                lastName = nameParts[1];
            } else if (nameParts.length === 1) {
                firstName = nameParts[0];
                lastName = '';
            } else {
                console.warn("Le format de l'email ne correspond pas au format attendu prénom.nom@domaine.com");
            }

            const capitalize = (str) => str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

            let conjointValueUpdated = ' ';

            if (branched === 'Habitat Social' && data?.formule.includes('Famille')) {
                conjointValueUpdated = '-';
            } else {
                conjointValueUpdated = numConjointsUpdate || conjointStatus;
            }

            if (branched?.includes('Bureaux') && data?.formule?.includes('Famille')) {
                conjointValueUpdated = conjointStatus;
            }

            setData((prevData) => {
                if (prevData.formule.includes('Famille')) {
                    return {
                        ...prevData,
                        conjoint: numConjointsUpdate,
                        newConjoint: conjointValueUpdated,
                        nbenfants: numEnfant !== 'undefined' ? numEnfant : '-',
                        nbascandants: numAscendants !== 'undefined' ? numAscendants : '-',
                        newNbenfants: numEnfantsUpdate !== 'undefined' ? numEnfantsUpdate : '-',
                        newNbascandants: numAscendingUpdate !== 'undefined' ? numAscendingUpdate : '-',
                        userDate: formatDateFR(parsedUser.date),
                        comprenom: capitalize(firstName),
                        comnom: capitalize(lastName)
                    };
                } else {
                    return {
                        ...prevData,
                        conjoint: conjoint || 'non',
                        newConjoint: numConjointsUpdate || conjointStatus,
                        nbenfants: numEnfant !== 'undefined' ? numEnfant : '0',
                        nbascandants: numAscendants !== 'undefined' ? numAscendants : '0',
                        newNbenfants: numEnfantsUpdate !== 'undefined' ? numEnfantsUpdate : '0',
                        newNbascandants: numAscendingUpdate !== 'undefined' ? numAscendingUpdate : '0',
                        userDate: formatDateFR(parsedUser.date),
                        comprenom: capitalize(firstName),
                        comnom: capitalize(lastName)
                    };
                }

                // return updatedData;
            });
        }

        const selectedChampsCookie = Cookies.get('selectedChamps');
        const selectedChamps = selectedChampsCookie ? JSON.parse(selectedChampsCookie) : [];
        const hasConjoint = selectedChamps.some((champ) => champ.label && champ.label.includes('Conjoint'));
        const branchCookie = Cookies.get('branche');
        const formule = Cookies.get('setFormule');

        if (branchCookie.includes('Bureaux') && formule?.includes('Conjoint')) {
            const newConjointValue = hasConjoint ? 'oui' : 'non';
            setData((prevData) => ({
                ...prevData,
                newConjoint: newConjointValue
            }));
        }

        if (branchCookie.includes('Habitat') && formule?.includes('Isolé')) {
            setData((prevData) => ({
                ...prevData,
                newConjoint: conjointStatus
            }));
        }

        console.log("Nouveau state 'data':", data);
    }, []);

    return (
        <div className="tp2-container">
            <div className="tp2-title">
                <h2>PROPOSITION TARIFAIRE SANTÉ</h2>
            </div>
            <div className="tp2-body">
                <p>Références : {user.muna}</p>
                <p>Le {user.datecreation}</p>

                {!nomBranches.includes('Architecte') && !nomBranches.includes('Bureaux') ? (
                    <div>
                        <div className="contract-branch">
                            <h3>Contrat collectif obligatoire en place</h3>
                            <p>Nom du contrat : Planet'santé {nomBranches}</p>
                        </div>
                        <table className="table-1">
                            <thead className="header-table">
                                <th className="title-table">Régime</th>
                                <th className="title-table">Option obligatoire</th>
                                <th className="title-table">Formule tarifaire</th>
                                <th className="title-table">Nombre d'enfants</th>
                                <th className="title-table">Nombre d'ascendants</th>
                                <th className="title-table">Conjoint</th>
                            </thead>
                            <tbody className="body-table">
                                <td className="content-table">{data.regime}</td>
                                <td className="content-table">{data.optiobli}</td>
                                <td className="content-table">{data.formule}</td>
                                <td className="content-table">
                                    {data.formule.includes('Famille') && !data.nbenfants ? '-' : data.nbenfants}
                                </td>
                                <td className="content-table">{data.nbascandants || '-'}</td>
                                <td className="content-table">{data.conjoint}</td>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>
                        <div className="contract-branch">
                            <h3>Contrat collectif obligatoire en place</h3>
                            <p>Nom du contrat : Planet'santé {nomBranches}</p>
                        </div>
                        <table
                            className={
                                !nomBranches.includes('Architecte') && !nomBranches.includes('Bureaux')
                                    ? 'table-1'
                                    : 'table-2'
                            }
                        >
                            <thead className="header-table">
                                <th className="title-table">Régime</th>
                                <th className="title-table">Option obligatoire</th>
                                <th className="title-table">Formule tarifaire</th>
                                {!nomBranches.includes('Architecte') && <th className="title-table">Conjoint</th>}
                            </thead>
                            <tbody className="body-table">
                                <td className="content-table">{data.regime}</td>
                                <td className="content-table">{data.optiobli}</td>
                                <td className="content-table">{Cookies.get('setFormule')}</td>
                                {!nomBranches.includes('Architecte') && (
                                    <td className="content-table">{data.conjoint}</td>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
                {!nomBranches.includes('Architecte') && !nomBranches.includes('Bureaux') ? (
                    <div>
                        <div className="contract-branch">
                            <h3>Proposition pour la partie facultative</h3>
                        </div>
                        <table className="table-1">
                            <thead className="header-table">
                                <th className="title-table">Régime</th>
                                <th className="title-table">Option obligatoire</th>
                                <th className="title-table">Formule tarifaire</th>
                                <th className="title-table">Nombre d'enfants</th>
                                <th className="title-table">Nombre d'ascendants</th>
                                {!nomBranches.includes('Architecte') && <th className="title-table">Conjoint</th>}
                            </thead>
                            <tbody className="body-table">
                                <td className="content-table">{data.regime || '-'}</td>
                                <td className="content-table">{data.newOptiobli || '-'}</td>
                                <td className="content-table">{data.formule || '-'}</td>
                                <td className="content-table">{data.newNbenfants || '-'}</td>
                                <td className="content-table">{data.newNbascandants || '-'}</td>
                                {!nomBranches.includes('Architecte') && (
                                    <td className="content-table">{data.newConjoint}</td>
                                )}
                            </tbody>
                        </table>
                        <p className="tarif">
                            Votre tarif :{' '}
                            <i>
                                {percentage}% du PMSS soit {resultat || '0'} € (PMSS = {pmss} € en {year})
                            </i>
                        </p>
                    </div>
                ) : (
                    <div>
                        <div className="contract-branch">
                            <h3>Proposition pour la partie facultative</h3>
                        </div>
                        <table
                            className={
                                !nomBranches.includes('Architecte') && !nomBranches.includes('Bureaux')
                                    ? 'table-1'
                                    : 'table-2-falc'
                            }
                        >
                            <thead className="header-table">
                                <th className="title-table">Régime</th>
                                <th className="title-table">Option obligatoire</th>
                                <th className="title-table">Formule tarifaire</th>
                                {!nomBranches.includes('Architecte') && <th className="title-table">Conjoint</th>}
                            </thead>
                            <tbody className="body-table">
                                <td className="content-table">{data.regime || '-'}</td>
                                <td className="content-table">{data.newOptiobli || '-'}</td>
                                <td className="content-table">{data.formule || '-'}</td>
                                {!nomBranches.includes('Architecte') && (
                                    <td className="content-table">{data.newConjoint || '-'}</td>
                                )}
                            </tbody>
                        </table>
                        {nomBranches.includes('Bureaux') ? (
                            <p className="tarif">Votre tarif : {resultat || '0'} €</p>
                        ) : (
                            <p className="tarif">
                                Votre tarif :{' '}
                                <i>
                                    {percentage}% du PMSS soit {resultat || '0'} € (PMSS = {pmss} € en {year})
                                </i>
                            </p>
                        )}
                    </div>
                )}
                <p>
                    Date d'effet souhaitée : <i> {new Date(user.date).toLocaleDateString('fr-FR', options)}</i>
                </p>

                <p>
                    Notre proposition tient compte de la situation que vous avez déclarée, des tarifs et des garanties
                    en vigueur à ce jour. <br /> Ces tarifs sont revalorisés chaque année sur décision du Conseil
                    d'administration. <br /> Produit santé : le montant de vos échéances pourra être différent en
                    fonction de la date d'enregistrement du contrat. Cela ne modifie pas votre cotisation annuelle.
                </p>
                <p>
                    Si votre situation familiale change, la modification sera effectuée sur vos contrats collectifs et
                    individuels. <br /> Votre cotisation pourra, en fonction du changement, augmenter x ou baisser. Le
                    tarif indiqué sera prélevé sur votre compte personnel.
                </p>
            </div>
        </div>
    );
}

export default Template2;
