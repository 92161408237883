import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/stylesAuthentification/Auth.css';
import { Auth } from 'aws-amplify';
import Logo from './../assets/logo Prodbleu.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const initialState = {
    email: ''
};

function ForgotPassword() {
    const location = useLocation();
    const [formData, setFormData] = useState({
        email: location.state?.email || ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const navigate = useNavigate();

    const handleResetPassword = () => {
        Auth.forgotPassword(formData.email)
            .then((data) => console.log(data))
            .catch((err) => console.log(err));
        navigate('/resetUsingVerificationCode');
    };

    const handleBack = () => {
        navigate('/');
    };

    return (
        <div className="container-main">
            <div className="container-second">
                <div className="wrap">
                    <div className="auth-header">
                        <img src={Logo} alt="logo" className="logo-prodigeo" />
                        <h2 className="auth-title"> Mot de passe oublié </h2>
                    </div>
                    <form onSubmit={handleResetPassword}>
                        <label className="auth-label">Adresse email</label>
                        <input
                            value={formData.email}
                            type="text"
                            name="email"
                            className="auth-input"
                            placeholder="xxxx@xxx.com"
                            onChange={handleChange}
                            required
                        />
                        <button className="auth-btn">Valider</button>
                    </form>
                    <button type="submit" className="login-back" onClick={handleBack}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                            <ArrowBackIcon style={{ fontSize: '20px' }} />
                            Retour à la page de login
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
