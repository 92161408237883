import React, { useState, useEffect } from 'react';
import '../styles/stylesClient//FormClient.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Cookies from 'js-cookie';
import HeaderPage from '../components/componentsClient/HeaderPage';
import MessageSupport from '../components/componentsClient/MessageSupport';

const initialState = {
    civilite: '',
    muna: '',
    nom: '',
    prenom: '',
    adress: '',
    cp: '',
    ville: '',
    // date: new Date().toLocaleDateString('fr-FR'),
    date: '2025-01-01',
    datecreation: `${moment().format('DD/MM/YYYY')}`
};

function FormClient() {
    const [user, setUser] = useState(initialState);
    const navigate = useNavigate();

    useEffect(() => {
        const nextMonthFirstDay = new Date();
        nextMonthFirstDay.setMonth(nextMonthFirstDay.getMonth() + 1);
        nextMonthFirstDay.setDate(1);

        setUser((prevUser) => ({
            ...prevUser,
            date: nextMonthFirstDay.toISOString().split('T')[0]
        }));
    }, []);

    const handleChange = (e) => {
        let { name, value } = e.target;

        if (name === 'muna') {
            value = value.replace(/[^0-9.]+/g, '');
            if (value.length === 8 && !value.includes('.')) {
                value = value.slice(0, 8) + '.' + value.slice(8);
            }
            value = value.slice(0, 11);
        }

        if (name === 'nom' || name === 'prenom') {
            value = value.replace(/[^a-zA-ZàâäéèêëïîôöùûüçÀÂÄÉÈÊËÏÎÔÖÙÛÜÇ\s]+/g, '');
            value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        }

        if (name === 'nom') {
            value = value.toUpperCase();
        }

        if (name === 'cp') {
            value = value.replace(/[^0-9]+/g, '');
            value = value.slice(0, 5);
        }

        setUser({ ...user, [name]: value });
    };

    const handleSuivant = (event) => {
        event.preventDefault();

        if (
            !user.civilite ||
            !user.muna ||
            !user.nom ||
            !user.prenom ||
            !user.adress ||
            !user.cp ||
            !user.ville ||
            !user.date
        ) {
            toast.error(`veulliez remplir tous les champs ${''}`);
        } else {
            Cookies.set('user', JSON.stringify(user), { expires: 1 });
            navigate('/recapitulatif/PDF');
        }
        if (user.muna.length <= 10) {
            toast.error('votre champ : Muna doit contenir 10 chiffres');
            navigate('/formClient');
        }
    };

    const handlePrecedent = () => {
        navigate(-1);
    };

    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(user));
    });

    return (
        <div className="formClient">
            <div className="formClient-container">
                <HeaderPage title="Renseignez les champs suivants pour obtenir un devis" />
                <form className="formClient-body">
                    <label className="formClient-label">Civilité :</label>
                    <select
                        className="formClient-select"
                        name="civilite"
                        value={user.civilite}
                        onChange={handleChange}
                        style={{
                            color: user.civilite === '' ? 'gray' : 'black' // Appliquer du gris si aucune sélection
                        }}
                    >
                        <option value="" disabled>
                            --
                        </option>
                        <option value="Madame">Madame</option>
                        <option value="Monsieur">Monsieur</option>
                    </select>
                    <label className="formClient-label">MUNA :</label>
                    <input
                        className="formClient-input"
                        placeholder="ex:  XXXXXX.XX"
                        type="text"
                        name="muna"
                        value={user.muna}
                        onChange={handleChange}
                    />
                    <label className="formClient-label">Prénom :</label>
                    <input
                        className="formClient-input"
                        placeholder="Saisir le prénom"
                        type="text"
                        name="prenom"
                        value={user.prenom}
                        onChange={handleChange}
                    />
                    <label className="formClient-label">Nom :</label>
                    <input
                        className="formClient-input"
                        placeholder="Saisir le nom"
                        type="text"
                        name="nom"
                        value={user.nom}
                        onChange={handleChange}
                    />

                    <label className="formClient-label">Adresse :</label>
                    <input
                        className="formClient-input"
                        placeholder="Saisir l'adresse"
                        type="text"
                        name="adress"
                        value={user.adress}
                        onChange={handleChange}
                    />
                    <div className="formClient-center">
                        <div>
                            <label className="formClient-label">Code Postal :</label>
                            <input
                                className="formClient-input"
                                placeholder="Saisir le code postal "
                                type="text"
                                maxLength={5}
                                name="cp"
                                value={user.cp}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label className="formClient-label">Ville :</label>
                            <input
                                className="formClient-input formClient-input-large"
                                placeholder="Saisir la ville"
                                type="text"
                                name="ville"
                                value={user.ville}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <label className="formClient-label">Date d'effet souhaitée :</label>
                    <input
                        className="formClient-input"
                        type="date"
                        name="date"
                        value={user.date}
                        onChange={handleChange}
                        style={{
                            color: user.date === '2025-01-01' ? 'gray' : 'black'
                        }}
                    />
                </form>
                <div className="formClient-footer">
                    <button className="formClient-footer-btn" onClick={handlePrecedent}>
                        Précedent
                    </button>
                    <button className="formClient-footer-btn" type="submit" onClick={handleSuivant}>
                        Suivant
                    </button>
                </div>
                <MessageSupport />
            </div>
        </div>
    );
}

export default FormClient;
