import Cookies from "js-cookie";

export default function computerSwitchCase(
  selectedChamps,
  selectedFormule,
  selectedOption,
  isConjoint
) {
  let newSelectedChamps = [];
  const regimeCookie = Cookies.get("setRegime");
  const selectedBranch = Cookies.get("branche");
  ////console.log("la banche :", selectedBranch)
  const champsFromCookieRaw = Cookies.get("selectedChamps");
  let champsFromCookie;

  if (champsFromCookieRaw) {
      try {
          champsFromCookie = JSON.parse(champsFromCookieRaw);
      } catch (e) {
          console.error("Erreur lors de la tentative de parsage des champs depuis les cookies :", e);
          champsFromCookie = []; 
      }
  } else {
      console.error("Aucune donnée 'selectedChamps' trouvée dans les cookies.");
      champsFromCookie = []; 
  }

  //console.log("Champs from cookie:", champsFromCookie.map(champ => champ.label || champ.nom));
  //console.log("champs selected:", JSON.stringify(selectedChamps, null, 2));
  ////console.log("from cookie :", JSON.stringify(champsFromCookie, null, 2 ));

  var basedFormuleCookie = Cookies.get("setFormule");
  ////console.log("Setting formule" , basedFormuleCookie)
  ////console.log("formule:", JSON.stringify(selectedFormule, null, 2))

  let matchingLabels = [];

  const champsSource = (selectedFormule.branche.nomBranche === "PTPC" || selectedFormule.branche.nomBranche === "Cartonnage") 
    ? selectedChamps 
    : champsFromCookie;
  
  if (
    selectedFormule &&
    selectedFormule.options &&
    selectedFormule.options.items
  ) {
    selectedFormule.options.items.forEach((option) => {
      if (option.regimes && option.regimes.items) {
        option.regimes.items.forEach((regime) => {
          if (regime.nomRegime === regimeCookie && regime.champs && regime.champs.items) {
            champsSource.forEach((champSource) => {
              if (champSource) { 
                const foundLabels = regime.champs.items.filter(
                  (champ) => (champ.nom === champSource.label || champ.label === champSource.label)
                );
                foundLabels.forEach((label) => {
                  if (label) { 
                    matchingLabels.push({
                      ...label,
                      optionName: option.nomOption,
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  
  // if (matchingLabels.length === 0) {
  //   console.error("Recherche des champs similaire introuvable");
  // }
  
  //console.log("Champs bdd:", JSON.stringify(matchingLabels, null, 2));
  
  var basedCookie = Cookies.get("setOption");
  //console.log("cookie de base :", basedCookie)
  let matchingConjoints = [];

  if (
    selectedFormule &&
    selectedFormule.options &&
    selectedFormule.options.items
  ) {
    selectedFormule.options.items.forEach((option) => {
      if (option.regimes && option.regimes.items) {
        option.regimes.items.forEach((regime) => {
          if (regime.nomRegime === regimeCookie) {
            const foundConjoints = regime.champs.items.filter(
              (champ) => champ.nom === "Conjoint"
            );
            
            foundConjoints.forEach((conjoint) => {
              matchingConjoints.push({
                ...conjoint,
                optionName: option.nomOption, 
              });
            });
          }
        });
      }
    });
  }

  //console.log("nos conjoint : ", JSON.stringify(matchingConjoints, null, 2))
  let matchingExtensions = [];

  if (
    selectedFormule &&
    selectedFormule.options &&
    selectedFormule.options.items
  ) {
    selectedFormule.options.items.forEach((option) => {
      if (option.regimes && option.regimes.items) {
        option.regimes.items.forEach((regime) => {
          if (regime.nomRegime === regimeCookie) {
            const foundExtensions = regime.champs.items.filter((champ) =>
              champ.nom.includes("Extension")
            );
            foundExtensions.forEach((extension) => {
              matchingExtensions.push({
                ...extension,
                optionName: option.nomOption, 
              });
            });
          }
        });
      }
    });
  }

  ////console.log("les estension " , JSON.stringify(matchingExtensions, null , 2))

  const basedChamps = (selectedChamps) => {
    const data = selectedChamps.map((champ) => {
      return {
        nom: champ.nom || champ.label,
        valeur: champ.valeur,
        id: champ.id,
      };
    });

    return data;
  };


  ////console.log("Matching conjoints:", JSON.stringify(matchingConjoints,null,2));

  const selectedOptionName = selectedOption ? selectedOption.nomOption : "";
  ////console.log("Selected option name:", selectedOptionName);

  const AvecConjoint =
    isConjoint ||
    champsFromCookie.some((champ) => champ.label?.includes("Conjoint"));
  ////console.log("Conjoint status:", AvecConjoint);

  let AvecSalarie = champsFromCookie.filter((champ) =>
  champ.nom?.includes("Salarié") || champ.label?.includes("Salarié")
);

 // //console.log("Données 'Salarié' trouvées :",JSON.stringify(AvecSalarie, null, 2));

  const routeFormule = `${selectedBranch} > ${basedFormuleCookie}`.trim();
 // //console.log("Route formule:", routeFormule);

  switch (routeFormule) {
    case "Bureaux d'Etudes Techniques > Famille-sans-conjoint":
      switch (basedCookie) {
        case "Option 1":
          if (selectedOptionName === "Option 2" && AvecConjoint) {
            const labelsMatchingOption = matchingLabels.filter(
              (label) => label.optionName === selectedOptionName
            );
            ////console.log("test", JSON.stringify(labelsMatchingOption, null, 2));
            const optionNumerSelected = parseInt(
              selectedOptionName.replace("Option ", "")
            );
            const conjointsMatchingOption = matchingConjoints.filter(
              (conjoint) => {
                const optionNumber = parseInt(
                  conjoint.optionName.replace("Option ", "")
                );
                return optionNumber <= optionNumerSelected;
              }
            );
            ////console.log("conj", conjointsMatchingOption)
            const fusionData = [
              ...labelsMatchingOption,
              ...conjointsMatchingOption,
            ];

            newSelectedChamps = fusionData.map((item) => ({
              id: item,
              nom: item.nom,
              valeur: item.valeur,
            }));

            /////console.log("Données fusion:", JSON.stringify(newSelectedChamps, null, 2));
          } else if (selectedOptionName === "Option 2") {
            newSelectedChamps = [...newSelectedChamps, ...selectedChamps];
          } else if (selectedOptionName === "Option 3" && AvecConjoint) {
            let selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );
            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return (
                labelOptionNumber === selectedOptionNumber ||
                labelOptionNumber === selectedOptionNumber - 1
              );
            });

            let conjointsFiltered = matchingConjoints.filter((conjoint) => {
              let conjointOptionNumber = parseInt(
                conjoint.optionName.replace("Option ", ""),
                10
              );
              return conjointOptionNumber <= selectedOptionNumber;
            });

            let fusionData = [...filteredLabels, ...conjointsFiltered];
            ////console.log("Données fusion:", JSON.stringify(fusionData, null, 2));
            newSelectedChamps = [...newSelectedChamps, ...fusionData];
          } else if (selectedOptionName === "Option 3") {
            let selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );
            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return (
                labelOptionNumber === selectedOptionNumber ||
                labelOptionNumber === selectedOptionNumber - 1
              );
            });
            newSelectedChamps = [...newSelectedChamps, ...filteredLabels];

            ////console.log("Labels filtrés:", JSON.stringify(filteredLabels, null, 2));
          } else if (selectedOptionName === "Option 4" && AvecConjoint) {
            const selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );

            const previousOptionNumber1 = selectedOptionNumber - 1;
            const previousOptionNumber2 = selectedOptionNumber - 2;

            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return (
                labelOptionNumber === selectedOptionNumber ||
                labelOptionNumber === previousOptionNumber1 ||
                labelOptionNumber === previousOptionNumber2
              );
            });
            let conjointsFiltered = matchingConjoints.filter((conjoint) => {
              let conjointOptionNumber = parseInt(
                conjoint.optionName.replace("Option ", ""),
                10
              );
              return conjointOptionNumber <= selectedOptionNumber;
            });

            let fusionData = [...filteredLabels, ...conjointsFiltered];
            newSelectedChamps = [...newSelectedChamps, ...fusionData];

            ////console.log("Données fusion:", JSON.stringify(fusionData, null, 2));
          } else if (selectedOptionName === "Option 4") {
            const selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );

            const previousOptionNumber1 = selectedOptionNumber - 1;
            const previousOptionNumber2 = selectedOptionNumber - 2;

            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return (
                labelOptionNumber === selectedOptionNumber ||
                labelOptionNumber === previousOptionNumber1 ||
                labelOptionNumber === previousOptionNumber2
              );
            });

            ////console.log("Labels filtrés (Option sélectionnée et 2 précédentes):", JSON.stringify(filteredLabels, null, 2));
            newSelectedChamps = [...newSelectedChamps, ...filteredLabels];
          } else if (AvecConjoint) {
            const conjointsForOption = matchingConjoints.filter(
              (conjoint) => conjoint.optionName === basedCookie
            );
            if (conjointsForOption.length > 0) {
              //console.log( "Données du conjoint pour l'option 1:",JSON.stringify(conjointsForOption, null, 2) );
              newSelectedChamps = [...newSelectedChamps, ...conjointsForOption];
            }
          } else {
            //console.log("Aucune condition ne s'est exécutée");
            newSelectedChamps = [];
          }

          break;
        case "Option 2":
          //console.log("Option 2 form1");
          if (selectedOptionName === "Option 3" && AvecConjoint) {
            const labelsMatchingOption = matchingLabels.filter(
              (label) => label.optionName === selectedOptionName
            );
            ////console.log("test", JSON.stringify(labelsMatchingOption, null, 2));
            const optionNumerSelected = parseInt(
              selectedOptionName.replace("Option ", "")
            );
            const conjointsMatchingOption = matchingConjoints.filter(
              (conjoint) => {
                const optionNumber = parseInt(
                  conjoint.optionName.replace("Option ", "")
                );
                return optionNumber <= optionNumerSelected;
              }
            );
            ////console.log("conj", conjointsMatchingOption)
            const fusionData = [
              ...labelsMatchingOption,
              ...conjointsMatchingOption,
            ];

            newSelectedChamps = fusionData.map((item) => ({
              id: item,
              nom: item.nom,
              valeur: item.valeur,
            }));

            ////console.log("Données fusion:", JSON.stringify(newSelectedChamps, null, 2));
          } else if (selectedOptionName === "Option 3") {
            newSelectedChamps = [...newSelectedChamps, ...selectedChamps];
          } else if (selectedOptionName === "Option 4" && AvecConjoint) {
            let selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );
            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              // Garder les labels de l'option sélectionnée et de l'option juste avant
              return (
                labelOptionNumber === selectedOptionNumber ||
                labelOptionNumber === selectedOptionNumber - 1
              );
            });

            let conjointsFiltered = matchingConjoints.filter((conjoint) => {
              let conjointOptionNumber = parseInt(
                conjoint.optionName.replace("Option ", ""),
                10
              );
              return conjointOptionNumber <= selectedOptionNumber;
            });

            let fusionData = [...filteredLabels, ...conjointsFiltered];
            ////console.log("Données fusion:", JSON.stringify(fusionData, null, 2));
            newSelectedChamps = [...newSelectedChamps, ...fusionData];
          } else if (selectedOptionName === "Option 4") {
            let selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );
            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              // Garder les labels de l'option sélectionnée et de l'option juste avant
              return (
                labelOptionNumber === selectedOptionNumber ||
                labelOptionNumber === selectedOptionNumber - 1
              );
            });
            newSelectedChamps = [...newSelectedChamps, ...filteredLabels];

            ////console.log("Labels filtrés:", JSON.stringify(filteredLabels, null, 2));
          } else if (AvecConjoint) {
            const basedOptionNumber = parseInt(
              basedCookie.replace("Option ", ""),
              10
            );

            const conjointsForOptionAndPrevious = matchingConjoints.filter(
              (conjoint) => {
                let conjointOptionNumber = parseInt(
                  conjoint.optionName.replace("Option ", ""),
                  10
                );
                return conjointOptionNumber <= basedOptionNumber;
              }
            );

            if (conjointsForOptionAndPrevious.length > 0) {
              //console.log( "Données du conjoint pour l'option et précédentes:", JSON.stringify(conjointsForOptionAndPrevious, null, 2) );
              newSelectedChamps = [
                ...newSelectedChamps,
                ...conjointsForOptionAndPrevious,
              ];
            }
          } else {
            //console.log("Aucune condition ne s'est exécutée");
            newSelectedChamps = [];
          }
          break;
        case "Option 3":
          if (selectedOptionName === "Option 4" && AvecConjoint) {
            let selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );
            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              // Garder les labels de l'option sélectionnée et de l'option juste avant
              return labelOptionNumber === selectedOptionNumber;
            });

            let conjointsFiltered = matchingConjoints.filter((conjoint) => {
              let conjointOptionNumber = parseInt(
                conjoint.optionName.replace("Option ", ""),
                10
              );
              return conjointOptionNumber <= selectedOptionNumber;
            });

            let fusionData = [...filteredLabels, ...conjointsFiltered];
            ////console.log("Données fusion:", JSON.stringify(fusionData, null, 2));
            newSelectedChamps = [...newSelectedChamps, ...fusionData];
          } else if (selectedOptionName === "Option 4") {
            newSelectedChamps = [...newSelectedChamps, ...selectedChamps];
          } else if (AvecConjoint) {
            const basedOptionNumber = parseInt(
              basedCookie.replace("Option ", ""),
              10
            );

            const conjointsForOptionAndPrevious = matchingConjoints.filter(
              (conjoint) => {
                let conjointOptionNumber = parseInt(
                  conjoint.optionName.replace("Option ", ""),
                  10
                );
                return conjointOptionNumber <= basedOptionNumber;
              }
            );

            if (conjointsForOptionAndPrevious.length > 0) {
              //console.log( "Données du conjoint pour l'option et précédentes:", JSON.stringify(conjointsForOptionAndPrevious, null, 2));
              newSelectedChamps = [
                ...newSelectedChamps,
                ...conjointsForOptionAndPrevious,
              ];
            }
          } else {
            //console.log("Aucune condition ne s'est exécutée");
            newSelectedChamps = [];
          }
          break;
        case "Option 4":
          if (AvecConjoint) {
            const basedOptionNumber = parseInt(
              basedCookie.replace("Option ", ""),
              10
            );

            const conjointsForOptionAndPrevious = matchingConjoints.filter(
              (conjoint) => {
                let conjointOptionNumber = parseInt(
                  conjoint.optionName.replace("Option ", ""),
                  10
                );
                return conjointOptionNumber <= basedOptionNumber;
              }
            );

            if (conjointsForOptionAndPrevious.length > 0) {
              //console.log( "Données du conjoint pour l'option et précédentes:", JSON.stringify(conjointsForOptionAndPrevious, null, 2));
              newSelectedChamps = [
                ...newSelectedChamps,
                ...conjointsForOptionAndPrevious,
              ];
            }
          } else {
            //console.log("Aucune condition ne s'est exécutée");
            newSelectedChamps = [];
          }
          break;
        default:
          //console.log( "Aucune option ou cas spécifique trouvé pour 'Famille-sans-conjoint'" );
          break;
      }
      break;

    case "Bureaux d'Etudes Techniques > Conjoint-distinct":
      //console.log("Formule 2");
      switch (basedCookie) {
        case "Option 1":
          if (selectedOptionName === "Option 2" && AvecConjoint) {
            const labelsMatchingOption = matchingLabels.filter(
              (label) => label.optionName === selectedOptionName
            );
            ////console.log("test", JSON.stringify(labelsMatchingOption, null, 2));
            const optionNumberSelected = parseInt(
              selectedOptionName.replace("Option ", "")
            );
            const conjointsMatchingOption = matchingConjoints.filter(
              (conjoint) => {
                const optionNumber = parseInt(
                  conjoint.optionName.replace("Option ", "")
                );
                return optionNumber === optionNumberSelected;
              }
            );
            ////console.log("conj", conjointsMatchingOption)
            const fusionData = [
              ...labelsMatchingOption,
              ...conjointsMatchingOption,
            ];

            newSelectedChamps = fusionData.map((item) => ({
              id: item,
              nom: item.nom,
              valeur: item.valeur,
            }));
          } else if (selectedOptionName === "Option 2") {
            const labelsMatchingOption = matchingLabels.filter(
              (label) => label.optionName === selectedOptionName
            );
            newSelectedChamps = [...newSelectedChamps, ...labelsMatchingOption];
          } else if (selectedOptionName === "Option 3" && AvecConjoint) {
            const selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );
            const previousOptionNumber1 = selectedOptionNumber - 1;

            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return (
                labelOptionNumber === selectedOptionNumber ||
                labelOptionNumber === previousOptionNumber1
              );
            });
            //console.log(  "Données correspondantes aux étiquettes:", filteredLabels );

            let conjointsFiltered = matchingConjoints.filter((conjoint) => {
              let conjointOptionNumber = parseInt(
                conjoint.optionName.replace("Option ", ""),
                10
              );
              return (
                conjointOptionNumber === selectedOptionNumber ||
                conjointOptionNumber === previousOptionNumber1
              );
            });
            //console.log( "Données correspondantes aux conjoints:", conjointsFiltered);

            let fusionData = [...filteredLabels, ...conjointsFiltered];
            newSelectedChamps = [...newSelectedChamps, ...fusionData];
          } else if (selectedOptionName === "Option 3") {
            const optionNumberSelected = parseInt(
              selectedOptionName.replace("Option ", "")
            );
            //console.log("Numéro d'option sélectionné:", optionNumberSelected);

            const matchingOptions = matchingLabels.filter((conjoint) => {
              const optionNumber = parseInt(
                conjoint.optionName.replace("Option ", "")
              );
              return (
                optionNumber === optionNumberSelected - 1 ||
                optionNumber === optionNumberSelected
              );
            });

            //console.log("Options correspondantes:", matchingOptions);

            newSelectedChamps = [...newSelectedChamps, ...matchingOptions];
          } else if (selectedOptionName === "Option 4" && AvecConjoint) {
            const selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );
            const previousOptionNumber1 = selectedOptionNumber - 1;
            const previousOptionNumber2 = selectedOptionNumber - 2;

            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return (
                labelOptionNumber === selectedOptionNumber ||
                labelOptionNumber === previousOptionNumber1 ||
                labelOptionNumber === previousOptionNumber2
              );
            });
            //console.log( "Données correspondantes aux étiquettes:", filteredLabels);

            let conjointsFiltered = matchingConjoints.filter((conjoint) => {
              let conjointOptionNumber = parseInt(
                conjoint.optionName.replace("Option ", ""),
                10
              );
              return (
                conjointOptionNumber === selectedOptionNumber ||
                conjointOptionNumber === previousOptionNumber1 ||
                conjointOptionNumber === previousOptionNumber2
              );
            });
            //console.log("Données correspondantes aux conjoints:", conjointsFiltered );

            let fusionData = [...filteredLabels, ...conjointsFiltered];
            newSelectedChamps = [...newSelectedChamps, ...fusionData];
          } else if (selectedOptionName === "Option 4") {
            const selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );

            const previousOptionNumber1 = selectedOptionNumber - 1;
            const previousOptionNumber2 = selectedOptionNumber - 2;

            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return (
                labelOptionNumber === selectedOptionNumber ||
                labelOptionNumber === previousOptionNumber1 ||
                labelOptionNumber === previousOptionNumber2
              );
            });

            newSelectedChamps = [...newSelectedChamps, ...filteredLabels];
          } 
          break;
        case "Option 2":
          if (selectedOptionName === "Option 3" && AvecConjoint) {
            const selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );

            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return labelOptionNumber === selectedOptionNumber;
            });

            let conjointsFiltered = matchingConjoints.filter((conjoint) => {
              let conjointOptionNumber = parseInt(
                conjoint.optionName.replace("Option ", ""),
                10
              );
              return conjointOptionNumber === selectedOptionNumber;
            });
            //console.log( "Données correspondantes aux étiquettes:", filteredLabels );

            let fusionData = [...filteredLabels, ...conjointsFiltered];
            newSelectedChamps = [...newSelectedChamps, ...fusionData];
          } else if (selectedOptionName === "Option 3") {
            const selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );
            const previousOptionNumber1 = selectedOptionNumber - 1;

            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return labelOptionNumber === selectedOptionNumber;
            });
            ////console.log("Données correspondantes aux étiquettes:", filteredLabels);

            let conjointsFiltered = matchingConjoints.filter((conjoint) => {
              let conjointOptionNumber = parseInt(
                conjoint.optionName.replace("Option ", ""),
                10
              );
              return (
                conjointOptionNumber === selectedOptionNumber ||
                conjointOptionNumber === previousOptionNumber1
              );
            });
            ////console.log("Données correspondantes aux conjoints:", conjointsFiltered);

            let fusionData = [...filteredLabels, ...conjointsFiltered];
            newSelectedChamps = [...newSelectedChamps, ...fusionData];
          } else if (selectedOptionName === "Option 4" && AvecConjoint) {
            const selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );
            const previousOptionNumber1 = selectedOptionNumber - 1;
            const previousOptionNumber2 = selectedOptionNumber - 2;

            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return (
                labelOptionNumber === selectedOptionNumber ||
                labelOptionNumber === previousOptionNumber1
              );
            });
            //console.log( "Données correspondantes aux étiquettes:", filteredLabels);

            let conjointsFiltered = matchingConjoints.filter((conjoint) => {
              let conjointOptionNumber = parseInt(
                conjoint.optionName.replace("Option ", ""),
                10
              );
              return (
                conjointOptionNumber === selectedOptionNumber ||
                conjointOptionNumber === previousOptionNumber1
              );
            });
            //console.log( "Données correspondantes aux conjoints:", conjointsFiltered );

            let fusionData = [...filteredLabels, ...conjointsFiltered];
            newSelectedChamps = [...newSelectedChamps, ...fusionData];
          } else if (selectedOptionName === "Option 4") {
            const selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );
            const previousOptionNumber1 = selectedOptionNumber - 1;
            const previousOptionNumber2 = selectedOptionNumber - 2;

            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return (
                labelOptionNumber === selectedOptionNumber ||
                labelOptionNumber === previousOptionNumber1
              );
            });
            //console.log( "Données correspondantes aux étiquettes:",filteredLabels);

            let conjointsFiltered = matchingConjoints.filter((conjoint) => {
              let conjointOptionNumber = parseInt(
                conjoint.optionName.replace("Option ", ""),
                10
              );
              return (
                conjointOptionNumber === selectedOptionNumber ||
                conjointOptionNumber === previousOptionNumber1 ||
                conjointOptionNumber === previousOptionNumber2
              );
            });
            //console.log( "Données correspondantes aux conjoints:", conjointsFiltered );

            let fusionData = [...filteredLabels, ...conjointsFiltered];
            newSelectedChamps = [...newSelectedChamps, ...fusionData];
          } else {
            //console.log("Aucune condition ne s'est exécutée");
          }
          break;
        case "Option 3":
          if (selectedOptionName === "Option 4" && AvecConjoint) {
            const selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );

            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return labelOptionNumber === selectedOptionNumber;
            });

            let conjointsFiltered = matchingConjoints.filter((conjoint) => {
              let conjointOptionNumber = parseInt(
                conjoint.optionName.replace("Option ", ""),
                10
              );
              return conjointOptionNumber === selectedOptionNumber;
            });
            //console.log( "Données correspondantes aux étiquettes:",filteredLabels);

            let fusionData = [...filteredLabels, ...conjointsFiltered];
            newSelectedChamps = [...newSelectedChamps, ...fusionData];
          } else if (selectedOptionName === "Option 4") {
            const selectedOptionNumber = parseInt(
              selectedOptionName.replace("Option ", ""),
              10
            );

            let filteredLabels = matchingLabels.filter((label) => {
              let labelOptionNumber = parseInt(
                label.optionName.replace("Option ", ""),
                10
              );
              return labelOptionNumber === selectedOptionNumber;
            });
            //console.log( "Données correspondantes aux étiquettes:", filteredLabels);

            newSelectedChamps = [...newSelectedChamps, ...filteredLabels];
          } else {
            //console.log("Aucune condition ne s'est exécutée");
          }

          break;
        case "Option 4":
          break;
      }
      break;
    case "Architecte > Isolé":
      switch (basedCookie) {
        case "Option 2":
          const hasExtensionDuo = selectedChamps.some(champ => 
            (champ.label && champ?.label.includes("Duo")) || (champ?.nom && champ.nom.includes("Duo"))
        );
        
        const hasExtensionFamille = selectedChamps.some(champ => 
            (champ.label && champ?.label.includes("Famille")) || (champ?.nom && champ.nom.includes("Famille"))
        );
        

          let detailsExtensionDuo = [];
          let detailsExtensionFamille = [];

          if (hasExtensionDuo) {
            detailsExtensionDuo = matchingExtensions.filter((extension) =>
              extension.nom.includes("Duo")
            );

            let adjustedExtensionDuo = detailsExtensionDuo.map((extension) => {
              let adjustedValue = extension.valeur;
              if (extension.optionName === selectedOptionName) {
                adjustedValue = (
                  parseFloat(extension.valeur.replace(",", ".")) - 0.31
                ).toFixed(2);
              }
              return {
                ...extension,
                valeur: adjustedValue.replace(".", ","),
              };
            });

            let adjustedSalarie = AvecSalarie.map((salarie) => {
              let adjustedValue = (
                parseFloat(salarie.valeur.replace(",", ".")) - 0.01
              ).toFixed(2);
              return {
                ...salarie,
                valeur: adjustedValue.replace(".", ","),
              };
            });

            let fusionDuoEtSalarie = [
              ...adjustedExtensionDuo,
              ...adjustedSalarie,
            ];
            ////console.log("Fusion Extension Duo ajustée avec Données 'Salarié':", JSON.stringify(fusionDuoEtSalarie, null, 2));

            return (newSelectedChamps = [
              ...newSelectedChamps,
              ...fusionDuoEtSalarie,
            ]);
          }

          if (hasExtensionFamille) {
            detailsExtensionFamille = matchingExtensions.filter((extension) =>
              extension.nom.includes("Famille")
            );


            let adjustedExtensionFamille = detailsExtensionFamille.map(
              (extension) => {
                let adjustedValue = extension.valeur; 
                if (extension.optionName === selectedOptionName) {
                  adjustedValue = (
                    parseFloat(extension.valeur.replace(",", ".")) - 0.32
                  ).toFixed(2);
                }

                return {
                  ...extension,
                  valeur: adjustedValue, 
                };
              }
            );

            let adjustedSalarie = AvecSalarie.map((salarie) => {
              let adjustedValue = (
                parseFloat(salarie.valeur.replace(",", ".")) - 0.01
              ).toFixed(2); // Utilisez "valeur" ici
              return {
                ...salarie,
                valeur: adjustedValue,
              };
            });

            let fusionFamille = [
              ...adjustedExtensionFamille,
              ...adjustedSalarie,
            ];
            ////console.log("Fusion Extension Famille ajustée avec Données 'Salarié':", JSON.stringify(fusionFamille, null, 2));

            newSelectedChamps = [...newSelectedChamps, ...fusionFamille];
          }

          break;
      }
      break;
    case "Cartonnage > Isolé":
      switch (basedCookie) {
        case "Option 1":
          if (selectedOptionName === 'Option 2' || selectedOptionName === 'Option 3') {
            const hasEnfants = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label.trim() === "Enfant(s)");
            const hasConjoint = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label.trim() === "Conjoint");
            let updatedChampsConjoint = [];
            let updatedChampsEnfants = [];
            //console.log("les champs a traiter ", JSON.stringify(selectedChamps, null, 2));

            if (hasEnfants) {
              const enfantToUpdateRaw = matchingLabels.find(mc => mc.nom === "Enfant(s)" && mc.optionName === basedCookie);
          
              const enfantToUpdate = enfantToUpdateRaw ? {
                  value: enfantToUpdateRaw.id,  
                  label: enfantToUpdateRaw.nom,
                  valeur: enfantToUpdateRaw.valeur
              } : null;
          
              //console.log("data enfant to update formatted", JSON.stringify(enfantToUpdate, null, 2));
          
              const enfantBased = [...selectedChamps];
              //console.log("data enfant based", JSON.stringify(enfantBased, null, 2));
          
              updatedChampsEnfants = enfantToUpdate ? [...enfantBased, enfantToUpdate] : [...enfantBased];
          
              //console.log("new selected champs", JSON.stringify(updatedChampsEnfants, null, 2))
          }

            if (hasConjoint) {
              const conjointToUpdaterow  = matchingConjoints.find(mc => mc.nom === "Conjoint" && mc.optionName === basedCookie);
              const cnjointToUpdate = conjointToUpdaterow ? {
                value: conjointToUpdaterow.id,  
                label: conjointToUpdaterow .nom,
                valeur: conjointToUpdaterow .valeur
            } : null;
            const conjointToBased = [...selectedChamps];
            updatedChampsConjoint = cnjointToUpdate? [...conjointToBased , cnjointToUpdate] : [...conjointToBased];

          }
          

          if (hasConjoint && hasEnfants) {
            let combinedChamps = [...updatedChampsConjoint, ...updatedChampsEnfants];
        
            const uniqueChamps = new Map();
        
            combinedChamps.forEach(champ => {
                const key = `${champ.valeur}-${champ.label}`;
                if (!uniqueChamps.has(key)) {
                    uniqueChamps.set(key, champ);
                } else {
                    const existingChamp = uniqueChamps.get(key);
                    existingChamp.valeur = champ.valeur;
                }
            });
        
            newSelectedChamps = Array.from(uniqueChamps.values());
            //console.log("Combined updated champs without duplicates", JSON.stringify(newSelectedChamps, null, 2));
            } else {
              newSelectedChamps = hasConjoint ? updatedChampsConjoint : updatedChampsEnfants;
          }
          }
          break;
          case 'Option 2':
            if (selectedOptionName === 'Option 3') {
              const hasEnfants = selectedChamps.some(sc => sc.label && typeof sc.label === 'string' &&  sc.label === "Enfant(s)");
              const hasConjoint = selectedChamps.some(sc => sc.label && typeof sc.label === 'string' && sc.label.trim() === "Conjoint");
              let updatedChampsConjoint = [];
              let updatedChampsEnfants = [];

             //console.log("les champs a traiter ", JSON.stringify(selectedChamps, null, 2));


      
          
              if (hasEnfants) {
                const enfantsToUpdaterow = matchingLabels.filter(mc => mc.nom === "Enfant(s)" && mc.optionName === "Option 1");
                const enfantsTran = enfantsToUpdaterow.map(enfant => {
                  const valeurUpdatedChamps = (parseFloat(enfant.valeur.replace(',', '.')) + 0.08).toFixed(2).replace('.', ',');
                  
                  return {
                    value: enfant.id,
                    label: enfant.nom,
                    valeur: valeurUpdatedChamps
                  };
                });
                //console.log("set enfant", JSON.stringify(enfantsTran, null, 2));
            
                const enfantToUpdate = matchingLabels.find(mc => mc.nom === "Enfant(s)" && mc.optionName === basedCookie);
                //console.log("enfant to update", JSON.stringify(enfantToUpdate, null, 2));
                            
                if (enfantToUpdate) {
                    let updatedValeur = parseFloat(enfantToUpdate.valeur.replace(',', '.')) + 0.01;
                    updatedValeur = updatedValeur.toFixed(2).replace('.', ',');
            
                    const updatedEnfant = {
                        value: enfantToUpdate.id,
                        label: enfantToUpdate.nom,
                        valeur: updatedValeur
                    };
            
                    const nonEnfantChamps = selectedChamps.filter(champ => champ.label !== "Enfant(s)" && champ.label !== "Salarié");
            
                    updatedChampsEnfants = [...nonEnfantChamps, updatedEnfant, ...enfantsTran];
                } 
            }
            
            if (hasConjoint) {
              const conjointsToKeep = matchingConjoints
              .filter(mc => mc.nom === "Conjoint" && mc.optionName === "Option 1")
              .map(conjoint => {
                const valeurUpdated = (parseFloat(conjoint.valeur.replace(',', '.')) + 0.25).toFixed(2).replace('.', ',');
                return {
                  value: conjoint.id,
                  label: conjoint.nom,
                  valeur: valeurUpdated
                };
              });
          
              console.log("Conjoint(s) pour Option 1, inchangés", JSON.stringify(conjointsToKeep, null, 2));
          
              const conjointToUpdateBased = matchingConjoints.find(mc => mc.nom === "Conjoint" && mc.optionName === basedCookie);
              
              if (conjointToUpdateBased) {
                  let updatedValeur = parseFloat(conjointToUpdateBased.valeur.replace(',', '.')) + 0.03; 
                  updatedValeur = updatedValeur.toFixed(2).replace('.', ','); 
          
                  const updatedConjointBased = {
                      value: conjointToUpdateBased.id,
                      label: conjointToUpdateBased.nom,
                      valeur: updatedValeur
                  };
          
                  //console.log("Conjoint de basedCookie mis à jour", JSON.stringify(updatedConjointBased, null, 2));
          
                  const nonConjointChamps = selectedChamps.filter(champ => champ.label !== "Conjoint");
          
                  updatedChampsConjoint = [ updatedConjointBased, ...conjointsToKeep];
              } else {
                  console.error("Aucun champ 'Conjoint' trouvé pour l'option et le régime basés sur 'basedCookie'");
              }
          }
          
            
            
              
              if (hasConjoint && hasEnfants) {
                let combinedChamps = [...updatedChampsConjoint, ...updatedChampsEnfants];
                //console.log("selectedChamps combined: ", JSON.stringify(selectedChamps, null, 2));
                //console.log("combined champs", JSON.stringify(combinedChamps, null, 2));
            
                combinedChamps = combinedChamps.filter(combined => 
                    !selectedChamps.some(selected => selected.value === combined.value && selected.label === combined.label)
                );
            
                const uniqueChamps = new Map();
            
                combinedChamps.forEach(champ => {
                    const key = `${champ.valeur}-${champ.label}`;
                    if (!uniqueChamps.has(key)) {
                        uniqueChamps.set(key, champ);
                    } else {
                        const existingChamp = uniqueChamps.get(key);
                        if (existingChamp.valeur !== champ.valeur) {
                            existingChamp.valeur = champ.valeur; 
                        }
                    }
                });
            
                newSelectedChamps = Array.from(uniqueChamps.values());
                //console.log("Combined updated champs without duplicates", JSON.stringify(newSelectedChamps, null, 2));
            } else {
                newSelectedChamps = hasConjoint ? updatedChampsConjoint : updatedChampsEnfants;
            }

            }else{
              const hasEnfants = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label === "Enfant(s)");
              const hasConjoint = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label === "Conjoint");
              let updatedChampsConjoint = [];
              let updatedChampsEnfants = [];
              
              if (hasEnfants) {
                const enfantToUpdateRaw = matchingLabels.filter(mc => mc.nom === "Enfant(s)" && mc.optionName === basedCookie);
            
                const enfantToKeepRaw = matchingLabels.filter(mc => mc.nom === "Enfant(s)" && mc.optionName === "Option 1");
            
                const enfantToUpdate = enfantToUpdateRaw.map(enfant => {
                    let valeurModifiee = parseFloat(enfant.valeur.replace(',', '.')) - 0.01; 
                    return {
                        value: enfant.id,
                        label: enfant.nom,
                        valeur: valeurModifiee.toFixed(2).replace('.', ',') 
                    };
                });
            
                const updatedEnfants = [...enfantToUpdate, ...enfantToKeepRaw.map(enfant => ({
                    value: enfant.id,
                    label: enfant.nom,
                    valeur: enfant.valeur
                }))];
            
                //console.log("Data enfant to update formatted", JSON.stringify(updatedEnfants, null, 2));
                
                const nonEnfantChamps = selectedChamps.filter(champ => champ.label !== "Enfant(s)");
            
                updatedChampsEnfants = [...nonEnfantChamps, ...updatedEnfants];
            
                //console.log("Updated champs with Enfant(s) combined", JSON.stringify(updatedChampsEnfants, null, 2));
            }
            
            

            if (hasConjoint) {
              const conjointToUpdaterow = matchingConjoints.find(mc => mc.nom === "Conjoint" && mc.optionName === "Option 1");
              //console.log("Conjoint to update", JSON.stringify(conjointToUpdaterow, null, 2));
          
              if (conjointToUpdaterow) {
                  let valeurModifiee = parseFloat(conjointToUpdaterow.valeur.replace(',', '.')) - 0.02; 
                  const conjointToUpdate = {
                      value: conjointToUpdaterow.id,
                      label: conjointToUpdaterow.nom,
                      valeur: valeurModifiee.toFixed(2).replace('.', ',') 
                  };
                  const conjointToBased = [...selectedChamps];
                  updatedChampsConjoint = [...conjointToBased, conjointToUpdate]; 
              } 
          }
            

              if (hasConjoint && hasEnfants) {
              let combinedChamps = [...updatedChampsConjoint, ...updatedChampsEnfants];
          
              ////console.log("combined champs", JSON.stringify(combinedChamps, null, 2));
              const uniqueChamps = new Map();
          
              combinedChamps.forEach(champ => {
                  // Utiliser l'identifiant et le label comme clé unique pour identifier les doublons
                  const key = `${champ.valeur}-${champ.label}`;
                  if (!uniqueChamps.has(key)) {
                      uniqueChamps.set(key, champ);
                  } else {
                      // Si un doublon est trouvé, on peut choisir de mettre à jour la valeur ou simplement ignorer l'ajout
                      const existingChamp = uniqueChamps.get(key);
                      existingChamp.valeur = champ.valeur;
                  }
              });
          
              newSelectedChamps = Array.from(uniqueChamps.values());
              }else {
                // Si seulement l'un des deux types est présent, utilisez les champs mis à jour correspondants
                newSelectedChamps = hasConjoint ? updatedChampsConjoint : updatedChampsEnfants;
            }

            }
          break;   
          case "Option 3":
            const hasEnfants = selectedChamps.some(sc => sc.label && typeof sc.label === 'string' && sc.label.trim() === "Enfant(s)");
            const hasConjoint = selectedChamps.some(sc => sc.label && typeof sc.label === 'string' && sc.label.trim() === "Conjoint");
            let updatedChampsConjoint = [];
            let updatedChampsEnfants = [];
            
            //console.log("les champs continue", JSON.stringify(selectedChamps, null, 2))

            if (hasEnfants) {
              const enfantToUpdateRaw = matchingLabels.filter(mc => mc.nom === "Enfant(s)" && mc.optionName === basedCookie);
              const enfantToKeepRaw = matchingLabels.filter(mc => mc.nom === "Enfant(s)" && mc.optionName === "Option 1");
              
              const enfantsToUpdate = enfantToUpdateRaw.map(enfant => {
                  let valeur = parseFloat(enfant.valeur.replace(',', '.'));
                  valeur -= 0.01; 
                  return {
                      value: enfant.id,
                      label: enfant.nom,
                      valeur: valeur.toFixed(2).replace('.', ',') 
                  };
              });
          
              const enfantsToKeep = enfantToKeepRaw.map(enfant => ({
                  value: enfant.id,
                  label: enfant.nom,
                  valeur: enfant.valeur
              }));
          
              const enfantsUpdated = [...enfantsToUpdate, ...enfantsToKeep];
          
              const nonEnfantChamps = selectedChamps.filter(champ => champ.label !== "Enfant(s)");
          
              updatedChampsEnfants = [...nonEnfantChamps, ...enfantsUpdated];
          }
          
          if(hasConjoint){
            const conjointsToUpdate = matchingConjoints.filter(mc => mc.nom === "Conjoint" && (mc.optionName === basedCookie || mc.optionName === "Option 1"));
            /////console.log("conjoint", JSON.stringify(conjointsToUpdate, null, 2))
          const conjointsUpdated = conjointsToUpdate.map(conjoint => {
              let valeur = parseFloat(conjoint.valeur.replace(',', '.'));
              if (conjoint.optionName === "Option 2") {
                  valeur += 0.01; 
              } else {
                valeur -= 0.02;
              }
              return {
                  value: conjoint.id,
                  label: conjoint.nom,
                  valeur: valeur.toFixed(2).replace('.', ',') 
              };
          });
        
            const nonConjointChamps = selectedChamps.filter(champ => champ.label !== "Conjoint" && champ.label !== "Salarié");
        
            updatedChampsConjoint = [...nonConjointChamps, ...conjointsUpdated];
          } 
          if (hasConjoint && hasEnfants) {
            let combinedChamps = [...updatedChampsConjoint, ...updatedChampsEnfants];
        
            //console.log("combined champs", JSON.stringify(combinedChamps, null, 2));
            const uniqueChamps = new Map();
        
            combinedChamps.forEach(champ => {
                // Utiliser l'identifiant et le label comme clé unique pour identifier les doublons
                const key = `${champ.valeur}-${champ.label}`;
                if (!uniqueChamps.has(key)) {
                    uniqueChamps.set(key, champ);
                } else {
                    const existingChamp = uniqueChamps.get(key);
                    existingChamp.valeur = champ.valeur;
                }
            });
        
            newSelectedChamps = Array.from(uniqueChamps.values());
            //console.log("Combined updated champs without duplicates", JSON.stringify(newSelectedChamps, null, 2));
          } else {
              newSelectedChamps = hasConjoint ? updatedChampsConjoint : updatedChampsEnfants;
          }         
          break;
      }
      break;
    case "PTPC > Isolé":
      switch (basedCookie) {
        case "Option 1":
          // ici
          if (selectedOptionName === 'Option 2' || selectedOptionName === 'Option 3') {
            const hasEnfants = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label === "Enfant(s)");
            const hasConjoint = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label === "Conjoint");
            let updatedChampsConjoint = [];
            let updatedChampsEnfants = [];
            
            // ici
            if (hasConjoint && selectedOptionName === 'Option 2') {
              const conjointsToUpdate = matchingConjoints.filter(mc => mc.nom === "Conjoint" && (mc.optionName === basedCookie ));
              const conjointToUpdated = matchingConjoints.filter(mc => mc.nom === "Conjoint" && (mc.optionName === "Option 2"))

              console.log("NINO 1", updatedChampsConjoint)
              
              updatedChampsConjoint = selectedChamps.map(champ => {
                const conjointFound = conjointsToUpdate.find(conjoint => conjoint.id === champ.id);
                if (champ.label === "Conjoint" && conjointFound) {
                  return {...champ, valeur: conjointFound.valeur}; 
                }
                return champ;
              });

              console.log("NINO 2", updatedChampsConjoint)
      
              const currentConjointIds = new Set(selectedChamps.filter(champ => champ.label === "Conjoint").map(champ => champ.id));
              const newConjointsToAdd = conjointsToUpdate.filter(conjoint => !currentConjointIds.has(conjoint.id));
      
              updatedChampsConjoint = [...updatedChampsConjoint, ...newConjointsToAdd.map(conjoint => ({
                id: conjoint.id,
                label: "Conjoint",
                valeur: conjoint.valeur
              }))];
            
              console.log("Updated champs with Conjoint", JSON.stringify(updatedChampsConjoint, null, 2));
            } else {
              const conjointsToUpdate = matchingConjoints.filter(mc => mc.nom === "Conjoint" && (mc.optionName === "Option 1" || mc.optionName === "Option 3"));
              ////console.log("conjoint", JSON.stringify(conjointsToUpdate, null, 2))
              const conjointsUpdated = conjointsToUpdate.map(conjoint => {
                return {
                    value: conjoint.id,
                    label: conjoint.nom,
                    valeur: conjoint.valeur
                };
              });
          
              const nonConjointChamps = selectedChamps.filter(champ => champ.label !== "Conjoint" );
          
              updatedChampsConjoint = [...nonConjointChamps, ...conjointsUpdated];
          
              //console.log("Updated champs with Conjoint combined", JSON.stringify(updatedChampsConjoint, null, 2));
            }     
            
            // ici
            if (hasEnfants) {
              const enfantToUpdateRaw = matchingLabels.find(mc => mc.nom === "Enfant(s)" && mc.optionName === basedCookie);
          
              const enfantToUpdate = enfantToUpdateRaw ? {
                  value: enfantToUpdateRaw.id,  
                  label: enfantToUpdateRaw.nom,
                  valeur: enfantToUpdateRaw.valeur
              } : null;
          
              //console.log("data enfant to update formatted", JSON.stringify(enfantToUpdate, null, 2));
          
              const enfantBased = [...selectedChamps];
              //console.log("data enfant based", JSON.stringify(enfantBased, null, 2));
          
              updatedChampsEnfants = enfantToUpdate ? [...enfantBased, enfantToUpdate] : [...enfantBased];
            }
          
            // ici
            if (hasConjoint && hasEnfants) {
              let combinedChamps = [...updatedChampsConjoint, ...updatedChampsEnfants];
              // Y'a 2 fois conjoints avec 96 à vérifier peut être ça qui bug
              console.log("combinedChamps avant traitement:", JSON.stringify(combinedChamps, null, 2));
          
              //console.log("combined champs", JSON.stringify(combinedChamps, null, 2));
              const uniqueChamps = new Map();
          
              combinedChamps.forEach(champ => {
                  const key = `${champ.valeur}-${champ.label}`;
                  console.log(`Traitement du champ: valeur=${champ.valeur}, label=${champ.label}, key=${key}`);

                  if (!uniqueChamps.has(key)) {
                      uniqueChamps.set(key, champ);
                      console.log(`Ajout du champ à uniqueChamps:`, JSON.stringify(champ, null, 2));
                  } else {
                      const existingChamp = uniqueChamps.get(key);
                      existingChamp.valeur = champ.valeur;
                      console.log(`Mise à jour du champ existant:`, JSON.stringify(existingChamp, null, 2));
                  }
              });
          
              newSelectedChamps = Array.from(uniqueChamps.values());
              console.log("newSelectedChamps après traitement:", JSON.stringify(newSelectedChamps, null, 2));
            } else {
              return  newSelectedChamps = hasConjoint ? updatedChampsConjoint : updatedChampsEnfants;
              console.log("combined champs", JSON.stringify(newSelectedChamps, null, 2))
            }
          } 
        break;
        case "Option 2":
          if(selectedOptionName === "Option 3") {
            const hasEnfants = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label === "Enfant(s)");
            const hasConjoint = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label === "Conjoint");
            let updatedChampsConjoint = [];
            let updatedChampsEnfants = [];

            //console.log("donnée actuel ", JSON.stringify(selectedChamps, null, 2))
            if (hasConjoint) {      
              const conjointsToUpdate = matchingConjoints.filter(mc => mc.nom === "Conjoint" && (mc.optionName === basedCookie || mc.optionName === "Option 1"));
              const conjointsUpdated = conjointsToUpdate.map(conjoint => {
                let valeur = parseFloat(conjoint.valeur.replace(',', '.'));
                if (conjoint.optionName === basedCookie) {
                    valeur += 0.01; 
                }
                return {
                    value: conjoint.id,
                    label: conjoint.nom,
                    valeur: valeur.toFixed(2).replace('.', ',') 
                };
              });
          
              const nonConjointChamps = selectedChamps.filter(champ => champ.label !== "Conjoint" && champ.label !== "Salarié");
              updatedChampsConjoint = [...nonConjointChamps, ...conjointsUpdated];
            }
          
          
          
          
            if (hasEnfants) {
              const enfantsBasedCookie = matchingLabels.filter(mc => mc.nom === "Enfant(s)" && mc.optionName === basedCookie)
                  .map(enfant => ({
                      value: enfant.id,
                      label: enfant.nom,
                      valeur: enfant.valeur 
                  }));
              const enfantsOptionUn = matchingLabels.filter(mc => mc.nom === "Enfant(s)" && mc.optionName === "Option 1")
                  .map(enfant => ({
                      value: enfant.id,
                      label: enfant.nom,
                      valeur: enfant.valeur
                  }));
          
              const combinedEnfants = [...enfantsBasedCookie, ...enfantsOptionUn];
          
              const nonEnfantChamps = selectedChamps.filter(champ => champ.label !== "Enfant(s)");
          
              updatedChampsEnfants = [...nonEnfantChamps, ...combinedEnfants];
          
              //console.log("les champs enants", JSON.stringify(updatedChampsEnfants, null, 2));
            }
        
        
            if (hasConjoint && hasEnfants) {
              const combinedChamps = [...updatedChampsConjoint, ...updatedChampsEnfants];
          
              const uniqueChamps = new Map();
              combinedChamps.forEach(champ => {
                const key = champ.label + champ.value; 
                if (!uniqueChamps.has(key)) {
                    uniqueChamps.set(key, champ);
                } else {
                  const existing = uniqueChamps.get(key);
                  if (parseFloat(champ.valeur.replace(',', '.')) > parseFloat(existing.valeur.replace(',', '.'))) {
                      uniqueChamps.set(key, champ);
                  }
                }
              });
      
          
              newSelectedChamps = Array.from(uniqueChamps.values());
                ////console.log("Combined updated champs without duplicates", JSON.stringify(newSelectedChamps, null, 2));
      
                const uniqueSelectedChamps = new Set(selectedChamps.map(champ => champ.value + '-' + champ.label));
      
                newSelectedChamps = newSelectedChamps.filter(champ => {
                    const key = champ.value + '-' + champ.label;
                    return !uniqueSelectedChamps.has(key);
                });
      
            // //console.log("Champs combinés sans doublons", JSON.stringify(newSelectedChamps, null, 2));
            } else {
              return newSelectedChamps = hasConjoint ? updatedChampsConjoint : updatedChampsEnfants;
            }
        

          } else {
            const hasEnfants = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label === "Enfant(s)");
            const hasConjoint = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label === "Conjoint");
            let updatedChampsConjoint = [];
            let updatedChampsEnfants = [];

            if (hasConjoint) {
              const conjointToUpdaterow  = matchingConjoints.find(mc => mc.nom === "Conjoint" && mc.optionName === "Option 1");
              
              const cnjointToUpdate = conjointToUpdaterow ? {
                value: conjointToUpdaterow.id,  
                label: conjointToUpdaterow .nom,
                valeur: conjointToUpdaterow .valeur
            } : null;
            const conjointToBased = [...selectedChamps];
            updatedChampsConjoint = cnjointToUpdate? [...conjointToBased , cnjointToUpdate] : [...conjointToBased];

          }
          
            if (hasEnfants) {
              const enfantToUpdateRaw = matchingLabels.find(mc => mc.nom === "Enfant(s)" && mc.optionName === "Option 1");
          
              const enfantToUpdate = enfantToUpdateRaw ? {
                  value: enfantToUpdateRaw.id,  
                  label: enfantToUpdateRaw.nom,
                  valeur: enfantToUpdateRaw.valeur
              } : null;
          
              //console.log("data enfant to update formatted", JSON.stringify(enfantToUpdate, null, 2));
          
              const enfantBased = [...selectedChamps];
              //console.log("data enfant based", JSON.stringify(enfantBased, null, 2));
          
              updatedChampsEnfants = enfantToUpdate ? [...enfantBased, enfantToUpdate] : [...enfantBased];
          
              //console.log("new selected champs", JSON.stringify(updatedChampsEnfants, null, 2))
          }
          
        
            if (hasConjoint && hasEnfants) {
              let combinedChamps = [...updatedChampsConjoint, ...updatedChampsEnfants];
          
              ////console.log("combined champs", JSON.stringify(combinedChamps, null, 2));
              const uniqueChamps = new Map();
          
              combinedChamps.forEach(champ => {
                  const key = `${champ.valeur}-${champ.label}`;
                  if (!uniqueChamps.has(key)) {
                      uniqueChamps.set(key, champ);
                  } else {
                      const existingChamp = uniqueChamps.get(key);
                      existingChamp.valeur = champ.valeur;
                  }
              });
          
              newSelectedChamps = Array.from(uniqueChamps.values());
              ////console.log("Combined updated champs without duplicates", JSON.stringify(newSelectedChamps, null, 2));
          } else {
              newSelectedChamps = hasConjoint ? updatedChampsConjoint : updatedChampsEnfants;
          }
          }
         // //console.log("cas 2 de la manipulatio de donnée ")
          break;
        case "Option 3":
          const hasEnfants = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label === "Enfant(s)");
            const hasConjoint = selectedChamps.some(sc =>sc.label && typeof sc.label === 'string' &&  sc.label === "Conjoint");
            let updatedChampsConjoint = [];
            let updatedChampsEnfants = [];
          if(hasEnfants){
            const enfantToUpdateRaw = matchingLabels.filter(mc => mc.nom === "Enfant(s)" && (mc.optionName === basedCookie || mc.optionName === "Option 1"));
          
            const enfantToUpdate = enfantToUpdateRaw.map(enfant => ({
                value: enfant.id,
                label: enfant.nom,
                valeur: enfant.valeur
            }));
        
            ////console.log("data enfant to update formatted", JSON.stringify(enfantToUpdate, null, 2));
            
            const nonEnfantChamps = selectedChamps.filter(champ => champ.label !== "Enfant(s)");
        
            updatedChampsEnfants = [...nonEnfantChamps, ...enfantToUpdate];
          }
          if(hasConjoint){
            const conjointsToUpdate = matchingConjoints.filter(mc => mc.nom === "Conjoint" && (mc.optionName === basedCookie || mc.optionName === "Option 1"));
            /////console.log("conjoint", JSON.stringify(conjointsToUpdate, null, 2))
          const conjointsUpdated = conjointsToUpdate.map(conjoint => {
              let valeur = parseFloat(conjoint.valeur.replace(',', '.'));
              if (conjoint.optionName === "Option 2") {
                  valeur += 0.01; 
              }
              return {
                  value: conjoint.id,
                  label: conjoint.nom,
                  valeur: valeur.toFixed(2).replace('.', ',') 
              };
          });
        
            const nonConjointChamps = selectedChamps.filter(champ => champ.label !== "Conjoint" && champ.label !== "Salarié");
        
            updatedChampsConjoint = [...nonConjointChamps, ...conjointsUpdated];
          } 
          if(hasConjoint && hasEnfants){
            const combinedChamps = [...updatedChampsConjoint, ...updatedChampsEnfants];
    
            const uniqueChamps = new Map();
            combinedChamps.forEach(champ => {
                const key = champ.label + champ.value; 
                if (!uniqueChamps.has(key)) {
                    uniqueChamps.set(key, champ);
                } else {
                    const existing = uniqueChamps.get(key);
                    if (parseFloat(champ.valeur.replace(',', '.')) > parseFloat(existing.valeur.replace(',', '.'))) {
                        uniqueChamps.set(key, champ);
                    }
                }
            });
        
            
            newSelectedChamps = Array.from(uniqueChamps.values());
              ////console.log("Combined updated champs without duplicates", JSON.stringify(newSelectedChamps, null, 2));
    
              const uniqueSelectedChamps = new Set(selectedChamps.map(champ => champ.value + '-' + champ.label));
    
              newSelectedChamps = newSelectedChamps.filter(champ => {
                  const key = champ.value + '-' + champ.label;
                  return !uniqueSelectedChamps.has(key);
              });
          } else {
              newSelectedChamps = hasConjoint ? updatedChampsConjoint : updatedChampsEnfants;
          }         
          //console.log("cas 3")
          break;
      }
      case "PTPC > Adulte/Enfant":
        switch (basedCookie) {
          case "Option 2":
            if (selectedOptionName === "Option 3") {
            const hasEnfants = selectedChamps.some(sc =>sc.nom && typeof sc.nom === 'string' &&  sc.nom === "Enfant(s)");
            const hasConjoint = selectedChamps.some(sc =>sc.nom && typeof sc.nom === 'string' &&  sc.nom === "Conjoint");
            
            let updatedChampsConjoint = [];
            let updatedChampsEnfants = [];
              if(hasConjoint){
                const conjointsToUpdate = matchingConjoints.filter(mc => mc.nom === "Conjoint"  && mc.optionName === "Option 2" );
                console.log(conjointsToUpdate)
                const conjointsUpdated = conjointsToUpdate.map(conjoint => {
                  let valeur = parseFloat(conjoint.valeur.replace(',', '.'));
                  if (conjoint.optionName === "Option 2") {
                      valeur += 0.01; 
                  }
                  return {
                      value: conjoint.id,
                      label: conjoint.nom,
                      valeur: valeur.toFixed(2).replace('.', ',') 
                  };
              });
            
                const nonConjointChamps = selectedChamps.filter(champ => champ.nom !== "Conjoint" );
            
                updatedChampsConjoint = [...nonConjointChamps, ...conjointsUpdated];
              }
              
              if (hasEnfants) {
                const enfantsToUpdate = matchingLabels.filter(mc => mc.nom === "Enfant(s)" &&  mc.optionName === "Option 2");
              
                const enfantsUpdated = enfantsToUpdate.map(enfant => {
                 // let valeur = parseFloat(enfant.valeur.replace(',', '.'));

                  return {
                      value: enfant.id,
                      label: enfant.nom,
                      valeur: enfant.valeur
                  };
                });
              
                const nonEnfantChamps = selectedChamps.filter(champ => champ.nom !== "Enfant(s)");
              
                updatedChampsEnfants = [...nonEnfantChamps, ...enfantsUpdated];

              }

              if(hasConjoint && hasEnfants){
                const combinedChamps = [...updatedChampsConjoint, ...updatedChampsEnfants];
        
                const uniqueChamps = new Map();
                combinedChamps.forEach(champ => {
                    const key = champ.label + champ.value; 
                    if (!uniqueChamps.has(key)) {
                        uniqueChamps.set(key, champ);
                    } else {
                        const existing = uniqueChamps.get(key);
                        if (parseFloat(champ.valeur.replace(',', '.')) > parseFloat(existing.valeur.replace(',', '.'))) {
                            uniqueChamps.set(key, champ);
                        }
                    }
                });
            
                
                newSelectedChamps = Array.from(uniqueChamps.values());
                  ////console.log("Combined updated champs without duplicates", JSON.stringify(newSelectedChamps, null, 2));
        
                  const uniqueSelectedChamps = new Set(selectedChamps.map(champ => champ.value + '-' + champ.label));
        
                  newSelectedChamps = newSelectedChamps.filter(champ => {
                      const key = champ.value + '-' + champ.label;
                      return !uniqueSelectedChamps.has(key);
                  });
              } else {
                 return  newSelectedChamps = hasConjoint ? updatedChampsConjoint : updatedChampsEnfants;
              }         
             
            }
        break;
        }
        case "Cartonnage > Adulte/Enfant":
          switch (basedCookie) {
            case "Option 2":
              if (selectedOptionName === "Option 3") {
                const hasEnfants = selectedChamps.some(sc =>sc.nom && typeof sc.nom === 'string' &&  sc.nom === "Enfant(s)");
                const hasConjoint = selectedChamps.some(sc =>sc.nom && typeof sc.nom === 'string' &&  sc.nom === "Conjoint");
                
                let updatedChampsConjoint = [];
                let updatedChampsEnfants = [];
                  if(hasConjoint){
                    const conjointsToUpdate = matchingConjoints.filter(mc => mc.nom === "Conjoint"  && mc.optionName === "Option 2" );
                    console.log(conjointsToUpdate)
                    const conjointsUpdated = conjointsToUpdate.map(conjoint => {
                      let valeur = parseFloat(conjoint.valeur.replace(',', '.'));
                      if (conjoint.optionName === "Option 2") {
                          valeur += 0.03; 
                      }
                      return {
                          value: conjoint.id,
                          label: conjoint.nom,
                          valeur: valeur.toFixed(2).replace('.', ',') 
                      };
                  });
                
                    const nonConjointChamps = selectedChamps.filter(champ => champ.nom !== "Conjoint" );
                
                    updatedChampsConjoint = [...nonConjointChamps, ...conjointsUpdated];
                  }
                  
                  if (hasEnfants) {
                    const enfantsToUpdate = matchingLabels.filter(mc => mc.nom === "Enfant(s)" &&  mc.optionName === "Option 2");
                  
                    const enfantsUpdated = enfantsToUpdate.map(enfant => {
                      let valeur = parseFloat(enfant.valeur.replace(',', '.'));
                      if (enfant.optionName === "Option 2") {
                        valeur += 0.01; 
                    }
                      return {
                          value: enfant.id,
                          label: enfant.nom,
                          valeur: valeur.toFixed(2).replace('.', ',') 
                      };
                    });
                  
                    const nonEnfantChamps = selectedChamps.filter(champ => champ.nom !== "Enfant(s)");
                  
                    updatedChampsEnfants = [...nonEnfantChamps, ...enfantsUpdated];
    
                  }
    
                  if(hasConjoint && hasEnfants){
                    const combinedChamps = [...updatedChampsConjoint, ...updatedChampsEnfants];
            
                    const uniqueChamps = new Map();
                    combinedChamps.forEach(champ => {
                        const key = champ.label + champ.value; 
                        if (!uniqueChamps.has(key)) {
                            uniqueChamps.set(key, champ);
                        } else {
                            const existing = uniqueChamps.get(key);
                            if (parseFloat(champ.valeur.replace(',', '.')) > parseFloat(existing.valeur.replace(',', '.'))) {
                                uniqueChamps.set(key, champ);
                            }
                        }
                    });
                
                    
                    newSelectedChamps = Array.from(uniqueChamps.values());
                      ////console.log("Combined updated champs without duplicates", JSON.stringify(newSelectedChamps, null, 2));
            
                      const uniqueSelectedChamps = new Set(selectedChamps.map(champ => champ.value + '-' + champ.label));
            
                      newSelectedChamps = newSelectedChamps.filter(champ => {
                          const key = champ.value + '-' + champ.label;
                          return !uniqueSelectedChamps.has(key);
                      });
                  } else {
                      newSelectedChamps = hasConjoint ? updatedChampsConjoint : updatedChampsEnfants;
                  }         
                 
                }
          break;
          }
      break;
    default:
      console.error("Aucun traitement n'a rempli les conditions");
  }

  return newSelectedChamps;
}
