import { Box, Container, Modal, CircularProgress, Typography } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { API } from 'aws-amplify';
import { useConfig } from '../Context/ConfigContext';
import Template1 from '../components/componentsClient/templates/Template1';
import Template2 from '../components/componentsClient/templates/Template2';
import '../styles/stylesClient/PdfPage.css';
import { toast } from 'react-toastify';
import MessageSupport from '../components/componentsClient/MessageSupport';
import HeaderPage from '../components/componentsClient/HeaderPage';

function PdfPage() {
    // const page1Ref = useRef(null);
    // const page2Ref = useRef(null);
    const [openModal, setOpenModal] = useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [nouvelP, setNovelP] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    const userData = Cookies.get('user');
    const parsedUser = userData ? JSON.parse(userData) : {};
    const resultats = Cookies.get('resultat') || {};
    const percentages = Cookies.get('percentage') || {};
    const numConjoints = Cookies.get('numConjoints');

    // Utilisez une condition ternaire pour définir la variable 'conjoint'
    const conjoint = numConjoints === '1' ? 'oui' : 'non';

    const { pmss, year } = useConfig();

    // Met à jour avant de l'envoyer à l'API et donc du côté du back
    useEffect(() => {
        if (pmss || year) {
            setData((prevData) => ({
                ...prevData,
                pmss,
                year
            }));
        }
        console.log('PMSS mis à jour dans le state:', pmss);
        console.log('Year mis à jour dans le state:', year);
    }, [pmss, year]);

    const getFormuleValue = () => {
        const selectedChampsCookie = Cookies.get('selectedChamps');
        const selectedChamps = selectedChampsCookie ? JSON.parse(selectedChampsCookie) : [];

        const isOnlySalarie =
            selectedChamps.length === 1 &&
            selectedChamps.some(
                (champ) =>
                    champ?.label?.toLowerCase().includes('salarié') || champ?.nom?.toLowerCase().includes('salarié')
            );

        const hasConjoint = selectedChamps.some((champ) => champ.label && champ.label.includes('Conjoint'));

        const branchCookie = Cookies.get('branche');
        const updateFormule = Cookies.get('updateFormule');
        const formule = Cookies.get('setFormule');

        console.log('updateFormule', updateFormule);

        if (branchCookie && branchCookie.includes('Architecte')) {
            if (updateFormule) {
                return updateFormule.length === 0 ? formule : updateFormule;
            } else if (isOnlySalarie) {
                return Cookies.get('setFormule');
            } else {
                return formule;
            }
        } else {
            return formule;
        }
    };

    const [data, setData] = useState({
        muna: parsedUser.muna || false,
        datecreation: parsedUser.datecreation,
        branche: Cookies.get('branche') || '',
        regime: Cookies.get('setRegime') || '',
        optiobli: Cookies.get('setOption') || '',
        formule: Cookies.get('setFormule') || '',
        nbenfants: '',
        nbascandants: '',
        conjoint: conjoint || '',
        newRegime: Cookies.get('setRegime') || '',
        newOptiobli: Cookies.get('setOptionUpdate') || '',
        newFormule: getFormuleValue() || '',
        newNbenfants: '',
        newNbascandants: '',
        newConjoint: Cookies.get('numConjointsUpdate') || Cookies.get('isConjointbox') === 'true' ? 'oui ' : 'non',
        resultat: resultats || '0',
        percentage: percentages || '0',
        userDate: '',
        nom: parsedUser.nom,
        prenom: parsedUser.prenom,
        adress: parsedUser.adress,
        cp: parsedUser.cp,
        ville: parsedUser.ville,
        civilite: parsedUser.civilite,
        comprenom: '',
        comnom: '',
        pmss: pmss,
        year: year
    });

    const formatDateFR = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR');
    };

    useEffect(() => {
        const email = Cookies.get('email');
        const numEnfant = Cookies.get('numEnfants');
        const numAscendants = Cookies.get('numAscendants');
        const numEnfantsUpdate = Cookies.get('updatenumEnfants') || numEnfant;
        const numAscendingUpdate = Cookies.get('updatenumAscendants') || numAscendants;
        const formuled = Cookies.get('setFormule');
        const nomBranche = Cookies.get('branche');

        if (email) {
            const [fullName] = email.split('@');
            const nameParts = fullName ? fullName.split('.') : [];

            let firstName = '';
            let lastName = '';

            if (nameParts.length === 2) {
                firstName = nameParts[0];
                lastName = nameParts[1];
            } else if (nameParts.length === 1) {
                firstName = nameParts[0];
                lastName = '';
            } else {
                console.warn("Le format de l'email ne correspond pas au format attendu prénom.nom@domaine.com");
            }

            const capitalize = (str) => str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            const conjoint = numConjoints === '1' ? 'oui' : 'non';

            const isConjointBox = Cookies.get('isConjointbox');
            const conjointStatus = isConjointBox === 'true' ? 'oui' : 'non';

            setData((prevData) => {
                let updatedData = {
                    ...prevData,
                    nbenfants: numEnfant !== 'undefined' ? numEnfant : '0',
                    nbascandants: numAscendants !== 'undefined' ? numAscendants : '0',
                    newNbenfants: numEnfantsUpdate !== 'undefined' ? numEnfantsUpdate : '0',
                    newNbascandants: numAscendingUpdate !== 'undefined' ? numAscendingUpdate : '0',
                    userDate: formatDateFR(parsedUser.date),
                    comprenom: capitalize(firstName),
                    comnom: capitalize(lastName),
                    conjoint: prevData.conjoint,
                    newConjoint: Cookies.get('numConjointsUpdate') || conjointStatus
                };

                if (nomBranche.includes('Habitat') && formuled?.includes('Isolé')) {
                    updatedData.newConjoint = Cookies.get('isConjointbox') === 'true' ? 'oui' : 'non';
                }

                if (prevData.formule.includes('Famille') && !nomBranche.includes('Habitat')) {
                    updatedData = {
                        ...updatedData,
                        nbenfants: numEnfant !== 'undefined' ? numEnfant : '-',
                        nbascandants: numAscendants !== 'undefined' ? numAscendants : '-',
                        newNbenfants: numEnfantsUpdate !== 'undefined' ? numEnfantsUpdate : '-',
                        newNbascandants: numAscendingUpdate !== 'undefined' ? numAscendingUpdate : '-',
                        conjoint: conjoint,
                        newConjoint: Cookies.get('isConjointbox') === 'true' ? 'oui' : 'non'
                    };
                }

                if (nomBranche.includes('Habitat') && formuled?.includes('Famille')) {
                    updatedData = {
                        ...updatedData,
                        nbenfants: numEnfant !== 'undefined' ? numEnfant : '-',
                        nbascandants: numAscendants !== 'undefined' ? numAscendants : '-',
                        newNbenfants: numEnfantsUpdate !== 'undefined' ? numEnfantsUpdate : '-',
                        newNbascandants: numAscendingUpdate !== 'undefined' ? numAscendingUpdate : '-',
                        conjoint: '-',
                        newConjoint: '-'
                    };
                }

                if (nomBranche.includes('Habitat') && formuled?.includes('Famille')) {
                    updatedData = {
                        ...updatedData,
                        newConjoint: conjointStatus
                    };
                }
                console.log("Nouveaux state 'data':", updatedData);
                return updatedData;
            });
        }
    }, []);

    const handleDownload = async () => {
        setOpenModal(true);

        console.log("Données envoyées à l'API pour le PDF :", data);

        API.post('apiPdfGenerator', '/pdf', { body: data })
            .then((response) => {
                if (!data.pmss || data.pmss === 0) {
                    console.error("PMSS manquant ou invalide avant l'envoi !");
                    toast.error('Erreur : PMSS manquant, veuillez réessayer.');
                    return;
                }

                if (!data.year || data.year === 0) {
                    console.error("Année manquante ou invalide avant l'envoi!");
                    toast.error('Erreur : Année manquante, veuillez réessayer.');
                    return;
                }

                console.log("Réponse de l'API:", response);
                if (response.statusCode === 200 && response.body) {
                    const responseBody = JSON.parse(response.body);

                    if (responseBody.fileUrl) {
                        const fileUrl = responseBody.fileUrl;

                        const downloadLink = document.createElement('a');
                        downloadLink.href = fileUrl;
                        downloadLink.target = '_blank'; // Ouvrir dans un nouvel onglet
                        downloadLink.download = 'devis.pdf';
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);

                        toast.success('PDF téléchargé avec succès !');
                        setShowDownloadModal(true);
                    } else {
                        throw new Error("L'URL du fichier PDF est introuvable dans la réponse de l'API.");
                    }
                } else {
                    throw new Error("Réponse de l'API inattendue ou en erreur.");
                }
            })
            .catch((error) => {
                console.error('Erreur lors de la génération du PDF: ', error);
                toast.error(`Erreur : ${error.message || 'Un problème est survenu lors de la génération du PDF.'}`);
            })
            .finally(() => {
                setOpenModal(false);

                setNovelP(true);
            });
    };

    const handleDeco = () => {
        const allCookies = Cookies.get();
        for (let cookie in allCookies) {
            Cookies.remove(cookie);
        }
        navigate('/');
    };

    const handleAutreP = () => {
        navigate(`/updateStatus/${id}`);
    };

    const handleNouvel = () => {
        const allCookies = Cookies.get();

        Object.keys(allCookies).forEach((cookieName) => {
            if (cookieName !== 'email') {
                Cookies.remove(cookieName);
            }
        });
        navigate('/status');
    };

    return (
        <div className="pdf">
            <HeaderPage title="Résultat de la simulation" />
            <div className="download-container">
                <button className="pdf-download" onClick={handleDownload}>
                    Télécharger le PDF
                </button>
                {nouvelP && (
                    <button className="pdf-new" onClick={handleNouvel}>
                        Nouvelle proposition
                    </button>
                )}
            </div>
            <Container style={{ width: '1000px' }}>
                <div className="pdf-body">
                    <Template1 />
                    <Template2 />
                </div>
            </Container>
            <div className="pdf-modal-container">
                <Modal className="pdf-modal-progress" open={openModal}>
                    <Box className="pdf-modal-progress-body">
                        <CircularProgress style={{ color: 'whitesmoke' }} />
                        <Typography style={{ padding: '5%' }}>Génération du PDF en cours...</Typography>
                    </Box>
                </Modal>

                <Modal className="pdf-modal-end" open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
                    <Box className="pdf-modal-end-body">
                        <h2>Fin de la tarification</h2>
                        <div className="pdf-modal-end-text-container">
                            <div className="pdf-modal-end-text">
                                <p>
                                    Vous venez de télécharger le PDF qui se trouve à présent dans votre dossier de
                                    téléchargement.
                                </p>
                                <p>
                                    Vous pouvez l’envoyer accompagné du tableau de garanties via le COP : MAIL de
                                    l’environnement NHCB de COMET.
                                </p>
                            </div>
                        </div>
                        <div className="pdf-modal-end-btn">
                            <button className="pdf-modal-btn-back" onClick={() => setShowDownloadModal(false)}>
                                Retour
                            </button>
                            <button className="pdf-new" onClick={handleNouvel}>
                                Nouvelle proposition
                            </button>
                        </div>
                    </Box>
                </Modal>
            </div>
            <MessageSupport />
        </div>
    );
}

export default PdfPage;
