import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../stylesComponents/sCClient/HeaderPage.css';
import Logo from '../../assets/logo Prodbleu.png';

function HeaderPage({ title }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    };
    return (
        <div className="headerPage">
            <header>
                {title ? <h2>{title}</h2> : <img src={Logo} alt="logo" className="logo-prodigeo" />}
                <button onClick={handleClick} className="btn-deco">
                    {' '}
                    Se déconnecter
                </button>
            </header>
        </div>
    );
}

export default HeaderPage;
